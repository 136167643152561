<style lang="scss">
  .po-password-strength.risky {
    color: #f95e68;
  }

  .po-password-strength.guessable {
    color: #fb964d;
  }

  .po-password-strength.weak {
    color: #fdd244;
  }

  .po-password-strength.safe {
    color: #b0dc53;
  }

  .po-password-strength.secure {
    color: #35cc62;
  }
</style>
<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Account | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>

      <div class="w-full lg:w-3/4">
        <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
          <h2 class="font-bold mb-5">
            My account
          </h2>
          <div class="input-group">
            <label>First and last name</label>
            <input
              v-model="name"
              v-validate="{ required: true }"
              type="text"
              class="w-full"
              name="name"
            >
            <span class="error">{{ errors.first('name') }}</span>
          </div>

          <div class="input-group">
            <label>Email</label>
            <input
              id="email"
              v-model="email"
              v-validate="{ required: true, email: true }"
              type="text"
              class="w-full"
              name="email"
            >
            <span class="error">{{ errors.first('email') }}</span>
          </div>

          <button
            v-if="!isChangePassword"
            class="mt-2 mb-3 green-btn"
            @click="activateChangePassword"
          >
            Change password
          </button>
          <div v-if="isChangePassword">
            <div class="input-group">
              <label>Old password</label>
              <input
                id="old_password"
                v-model="old_password"
                v-validate="'required'"
                type="password"
                class="w-full"
                name="old_password"
                data-vv-as="password"
              >
              <span class="error">{{ errors.first('old_password') }}</span>
            </div>
            <div class="input-group">
              <label>New password <span class="text-grey-semi-light">(Include 8+ characters and at least one number)</span></label>
              <input
                v-model="new_password"
                v-validate="'required'"
                type="password"
                class="w-full"
                name="new_password"
                data-vv-as="password"
                @input="checkPassword"
              >
              <password-meter
                :password="new_password"
                @score="onPasswordScore"
              />
              <div
                class="mt-1 po-password-strength"
                :class="passwordStrength"
              >
                {{ passwordScore }}
              </div>
              <span class="error">{{ errors.first('new_password') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full px-4 mt-8 lg:px-0 lg:mt-4 mb-10 text-right">
        <v-button @click="save">
          Save
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'
import passwordMeter from 'vue-simple-password-meter'

  export default {
    name: 'SettingsAccount',
    components: { passwordMeter },
    data(){
      return {
        isSaving: false,
        name:'',
        email:'',
        old_password:'',
        new_password: '',
        isChangePassword: false,
        user: auth.user(),
        password_length: 0,
        contains_alphabet: false,
        contains_eight_characters: false,
        contains_number: false,
        contains_uppercase: false,
        contains_special_character: false,
        valid_password: true,
        passwordScore: null,
        passwordStrength: null,
      }
    },
    mounted(){
      this.name = this.user.me.full_name
      this.email = this.user.me.email
    },
    methods: {
      onPasswordScore({ score, strength }) {
        this.passwordStrength = strength
        if(score < 2){
          this.passwordScore = 'Weak'
        }
        if(score > 1 && score < 4){
          this.passwordScore = 'Average'
        }
        if(score == 4){
          this.passwordScore = 'Strong'
        }
      },
      checkPassword() {
        this.password_length = this.new_password.length
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

        if (this.password_length > 7) {
          this.contains_eight_characters = true
        } else {
          this.contains_eight_characters = false
        }

        this.contains_alphabet = /[A-Za-z]/.test(this.new_password)
        this.contains_number = /\d/.test(this.new_password)
        this.contains_uppercase = /[A-Z]/.test(this.new_password)
        this.contains_special_character = format.test(this.new_password)

        if (this.contains_alphabet === true && this.contains_eight_characters === true && this.contains_number === true) {
          this.valid_password = true
        } else {
          this.valid_password = false
        }
      },
      activateChangePassword(){
        this.isChangePassword = true
      },
      save(){
        this.isSaving = true

        if(this.isChangePassword){
          if(!this.valid_password){
            this.$toasted.global.general_error({
              message : 'Please check if password contains 8+ characters and at least one number.'
            })
            return false
          }
        }
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
           }
        })
      },
      async doSave(){
        this.isSaving = true

        const postData = {
          userId: this.user.me.id,
          name: this.name,
          update_email: this.email,
          isChangePassword: this.isChangePassword,
          old_password: this.old_password,
          new_password: this.new_password,
        }

        try {
          const { data } = await this.$api.get('user').patch(postData)
          this.$toasted.global.general_success({
            message : 'Account details updated.'
          })
        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }

        await auth.refreshUser()
        window.location.reload()
      }
    },
  }
</script>
